import axios from 'axios';

const binanceHost = '/api/v1/tradingView';
const Axios = axios.create({
  responseType: 'json',
  baseURL: binanceHost,
});

export const serverTime = function () {
  return Axios.get('/serverTime').then(res => res.data.serverTime);
};

export const symbols = function () {
  return Axios.get('/exchangeInfo').then(res => res.data.symbols);
};

export const klines = function (exchange, pair, interval, startTime, endTime, limit) {
  const url =
    '/klines' +
    '?exchange='.concat(exchange) +
    '&pair='.concat(pair) +
    '&interval='.concat(interval) +
    '&limit='.concat(limit) +
    '&startTime='.concat(startTime) +
    '&endTime='.concat(endTime);
  return Axios.get(url).then(res => res.data);
};

export const marks = function (exchange, pair, interval, startTime, endTime) {
  const url =
    '/marks' +
    '?exchange='.concat(exchange) +
    '&pair='.concat(pair) +
    '&interval='.concat(interval) +
    '&startTime='.concat(startTime) +
    '&endTime='.concat(endTime);
  return Axios.get(url).then(res => {
    let withLocalTimestamps = [];
    res.data.forEach(element => {
      let text = [];
      element.tooltip.forEach(item => {
        if (item.split(' ').length === 3 && item.endsWith('convert')) {
          text.push(`${item.split(' ')[0]} ${new Date(parseFloat(item.split(' ')[1])).toLocaleTimeString()}`);
        } else {
          text.push(item);
        }
      });
      element.tooltip = text;
      withLocalTimestamps.push(element);
    });
    return withLocalTimestamps;
  });
};
